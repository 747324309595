<template>
  <div class="form-list" id="form-list">
    <portal to="top-bar">
      <div class="title">
        <h1>{{ $LOCAL('Search') }}</h1>
      </div>
    </portal>
    <error-handler
      v-if="displayErrorHandler"
      :error="displayError"
      :showError="showError && displayError.code !== 404"
      variant="danger"
      @dismiss-error="showError = false"
    />
    <search-sort-controls
      class="search-and-sort-controls"
      id="search-page-search-sort-controls"
      :key="searchInput"
      :loading="loading"
      :showSortAndRefresh="true"
      :hasClearSearchCross="true"
      :hasAdvancedSearch="false"
      :hasModelSelection="false"
      :currentSentSearch="searchInput"
      :searchCount="searchResultList.totalCount ? searchResultList.totalCount : 0"
      :searchNoun="$SEARCH('TEXT').WORD.RESULT_OPTIONAL_PLURAL"
      :sortFields="$SEARCH('DEFAULT_SORT_FIELDS')"
      :isForEntityOrLink="false"
      :defaultSortValues="$SEARCH('DEFAULT_SORT_VALUES')"
      :sortCustomIdField="$SEARCH('DEFAULT_SORT_CUSTOM_ID_FIELD')"
      :expandSearchBar="true"
      :isShowingSearchMessage="searchInput !== ''"
      :isForIndexedSearch="true"
      @sort="sort"
      @search="doSearch"
      @refresh="fetchSearchResultList(false)"
    />
    <div class="content">
      <div class="results-area">
        <result-cards
          :items="searchResultList.results"
          :searchQuery="searchInput"
          :hiddenFields="hiddenFields"
          :sortField="sortBy"
          :displayBlankFields="displayBlankFields"
        >
          <!--  :sortField="sortBy-->
          <template v-slot:name="{ item }">
            <router-link :to="goToViewPage(item)">
              <span class="result-cards-title">
                 <b-img v-if="getIcon(item)" class="content-header-icon icon img-thumbnail rounded-circle" :src="item.icon"/>
                 <IconEntity class="content-header-icon" v-if="!item.icon && allEntitiesIndex.includes(item.model)" ></IconEntity>
                 <IconLink class="content-header-icon" v-if="!item.icon && allLinksIndex.includes(item.model)" ></IconLink>
                {{ item._id }}
                <b-badge class="content-header-badge" variant="secondary">{{ displayModelLabel(item.model) }}</b-badge>
              </span>
            </router-link>
          </template>
        </result-cards>
        <b-button
          v-if="searchResultList.totalCount > searchResultList.results.length"
          class="load-more-button"
          variant="outline"
          @click="fetchSearchResultList(true)"
        >
          <b-badge pill variant="secondary" v-if="loading === false">{{
              $LOCAL('LoadMore')
            }}
          </b-badge>
          <b-spinner
            small
            v-if="loading === true"
            :label="$SEARCH('TEXT').WORD.LOADING"
          />
        </b-button>
      </div>
      <div class="side-bar-area">
        <div class="side-bar-box  shadow-sm">
          <div class="criteria-group" v-for="criteria of indexFilterCriteria" :key="criteria.criteriaKey">
            <strong>{{ criteria.criteriaKey }}</strong>
            <multiselect
              class="custom-multiselect"
              track-by="id"
              label="label"
              :options="criteria.criteriaValues"
              :placeholder="$SEARCH('TEXT').FILTER_BY + criteria.criteriaKey"
              :closeOnSelect="false"
              :taggable="false"
              :multiple="true"
              v-model="criteria.selectedValue"
              :selectLabel="$LOCAL('COMMON_WORD').SELECT"
              :deselectLabel="$LOCAL('COMMON_WORD').REMOVE"
              @select="onSelectIndexFilterCriteria"
              @remove="onRemoveIndexFilterCriteria"
            >
              <template slot-scope="{option}">{{ option.label }}</template>
            </multiselect>
          </div>
        </div>
        <div v-if="fieldsFilterCriteria.length > 0" class="side-bar-box  shadow-sm">
          <div class="criteria-group" v-for="criteria of fieldsFilterCriteria" :key="criteria.criteriaKey">
            <strong>{{ criteria.criteriaKey }}</strong>
            <multiselect
              class="custom-multiselect"
              track-by="value"
              label="label"
              :options="criteria.criteriaValues"
              :placeholder="$SEARCH('TEXT').FILTER_BY + criteria.criteriaKey"
              :closeOnSelect="false"
              :taggable="false"
              :multiple="true"
              v-model="criteria.selectedValue"
              :selectLabel="$LOCAL('COMMON_WORD').SELECT"
              :deselectLabel="$LOCAL('COMMON_WORD').REMOVE"
              @select="onSelectFieldsFilterCriteria"
              @remove="onRemoveFieldsFilterCriteria"
            >
              <template slot-scope="{option}">{{ option.label | snakeToTitle }}</template>
            </multiselect>
          </div>
        </div>
        <div class="side-bar-box  shadow-sm">
          <div class="criteria-group">
            <strong>{{ $SEARCH('TEXT').OTHER_FILTERS }}</strong>
            <b-form-checkbox size="sm" v-model="filterByAttachments" @change="fetchSearchResultList(false)">
              <span>{{ $SEARCH('TEXT').ATTACHMENTS }}</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import ResultCards from '@/modules/insight/components/ResultCards'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import multiselect from 'vue-multiselect'
import _ from 'lodash'
import SearchConstants from '@/constants/SEARCH.json'
import IconLink from '@/assets/link.svg'
import IconEntity from '@/assets/entity.svg'

export default {
  name: 'forms',
  components: {
    ErrorHandler,
    ResultCards,
    SearchSortControls,
    multiselect,
    IconLink,
    IconEntity
  },
  data: () => ({
    allModels: [],
    allEntitiesIndex: [],
    allLinksIndex: [],
    displayBlankFields: false,
    items: {},
    error: false,
    loading: false,
    page: 1,
    showError: false,
    displayError: {
      code: -1,
      message: ''
    },
    defaultSortBy: ['_score'],
    sortBy: ['_score'],
    hiddenFields: ['attachments'],
    baseFieldsFilterCriteria: [],
    fieldsFilterCriteria: [],
    indexFilterCriteria: [],
    searchResultList: {
      totalCount: 0,
      results: []
    },
    searchInput: '',
    filterByFields: [],
    filterByIndex: [],
    filterByAttachments: false
  }),
  computed: {
    ...mapState('search', {
      stateSearchResultList: 'searchResultList',
      searchResultError: 'error',
      searchResultErrorCode: 'errorCode',
      stateSearchInput: 'globalSearchInput'
    }),
    /**
     * To determine if error handler should be displayed
     * @returns {boolean} to display/not to display error section
     */
    displayErrorHandler () {
      return this.showError !== false && this.loading === false && this.displayError.code !== 404
    }
  },
  watch: {
    /**
     * To display search result error
     */
    searchResultError: {
      handler: function (newError) {
        if (newError !== false) {
          this.showError = true
          this.loading = false
          this.displayError = {
            code: this.searchResultErrorCode,
            message: newError
          }
        } else {
          // reset to not show error
          this.showError = false
          this.loading = false
          this.displayError = {
            code: -1,
            message: ''
          }
        }
      },
      deep: true
    },
    /**
     * To process new search triggered from navigation bar when user already in search page
     * @param newValue new search value input from navigation bar
     */
    stateSearchInput (newValue) {
      // input new nav search when in search page
      if (newValue.length > 0) {
        this.searchInput = newValue
        this.doSearch(this.searchInput)
        this.setGlobalSearchInput('')
      }
    }
  },
  async mounted () {
    this.allModels = await this.getIndexDisplay({})

    // load filter option
    const entities = _.filter(this.allModels, function (model) {
      return model.type === SearchConstants.VIEW_TYPES.ENTITIES
    })

    this.allEntitiesIndex = _.map(entities, 'id')
    const entitiesCriteriaValues = entities
    entitiesCriteriaValues.unshift({
      id: SearchConstants.TEXT.WORD.ALL_ENTITIES,
      label: SearchConstants.TEXT.WORD.ALL_ENTITIES
    })

    const links = _.filter(this.allModels, function (model) {
      return model.type === SearchConstants.VIEW_TYPES.LINKS
    })

    this.allLinksIndex = _.map(links, 'id')
    const linksCriteriaValues = links
    linksCriteriaValues.unshift({
      id: SearchConstants.TEXT.WORD.ALL_LINKS,
      label: SearchConstants.TEXT.WORD.ALL_LINKS
    })

    this.indexFilterCriteria = [
      {
        criteriaKey: SearchConstants.TEXT.WORD.ENTITIES,
        criteriaValues: entitiesCriteriaValues,
        selectedValue: []
      },
      {
        criteriaKey: SearchConstants.TEXT.WORD.LINKS,
        criteriaValues: linksCriteriaValues,
        selectedValue: []
      }
    ]

    // redirect from another page
    if (this.stateSearchInput.length > 0) {
      this.searchInput = this.stateSearchInput
      this.doSearch(this.searchInput)
      this.setGlobalSearchInput('')
    }
  },
  methods: {
    ...mapActions('search', [
      'getSearchResultList',
      'setGlobalSearchInput'
    ]),
    ...mapActions('insight', [
      'getIndexDisplay'
    ]),
    /**
     * To fetch search result list from backend via ElasticSearch
     * @param isAppend If isAppend true, will load next page of serach result
     * @returns {Promise<void>} Search result list
     */
    async fetchSearchResultList (isAppend = false) {
      if (this.searchInput.trim().length < 1) {
        return
      }
      this.loading = true
      if (isAppend) {
        this.page++
      } else {
        this.page = 1
      }

      // Process Filter By Index
      const filterByIndexParams = this.fetchSearchResultList_prepareFilterByIndex()

      // Process Filter By Fields
      const filterByFieldsParams = this.fetchSearchResultList_prepareFilterByFields()

      // Prepare params
      const params = {
        query: this.searchInput.trim(),
        fields: filterByFieldsParams.join(','),
        index: filterByIndexParams.join(','),
        page: this.page,
        ordering: this.sortBy.join(',')
      }
      const result = await this.getSearchResultList({ params: params })
      if (result) {
        await this.prepareModelDetailsForSearchResult()
        if (isAppend) {
          this.searchResultList.results = this.searchResultList.results.concat(_.cloneDeep(this.stateSearchResultList.results))
        } else {
          this.searchResultList = _.cloneDeep(this.stateSearchResultList)
        }
      }
      this.loading = false
    },
    /**
     * To prepare filter-by-fields - add filter by attachments
     * @returns {[]} array of filter by fields
     */
    fetchSearchResultList_prepareFilterByFields () {
      const filterByFieldsParams = _.cloneDeep(this.filterByFields)
      if (this.filterByAttachments) {
        filterByFieldsParams.push(SearchConstants.SEARCH_RESULT_ATTACHMENT_CONTENT_FIELD_NAME)
      }
      return filterByFieldsParams
    },
    /**
     * To prepare filter-by-index before sending search request to backend
     * @returns {[]} array of filter by index
     */
    fetchSearchResultList_prepareFilterByIndex () {
      let filterByIndexParams = _.cloneDeep(this.filterByIndex)
      const selectedAllEntitiesIndex = filterByIndexParams.indexOf(SearchConstants.TEXT.WORD.ALL_ENTITIES)
      if (selectedAllEntitiesIndex !== -1) {
        filterByIndexParams.splice(selectedAllEntitiesIndex, 1)
        filterByIndexParams = [...filterByIndexParams, ...this.allEntitiesIndex]
      }
      const selectedAllLinksIndex = filterByIndexParams.indexOf(SearchConstants.TEXT.WORD.ALL_LINKS)
      if (selectedAllLinksIndex !== -1) {
        filterByIndexParams.splice(selectedAllLinksIndex, 1)
        filterByIndexParams = [...filterByIndexParams, ...this.allLinksIndex]
      }
      return filterByIndexParams
    },
    /**
     * To trigger new search
     * @param searchInput
     */
    doSearch (searchInput) {
      if (searchInput.length > 0) {
        this.searchInput = searchInput
        this.filterByFields = []
        this.filterByIndex = []
        this.filterByAttachments = false
        this.sortBy = this.defaultSortBy
        this.resetFilterCriteria()
        this.fetchSearchResultList(false)
      }
    },
    /**
     * To trigger sort
     * @param sortBy
     */
    sort (sortBy) {
      this.sortBy = sortBy
      this.fetchSearchResultList(false)
    },
    /**
     * To go to view page of entity/link
     * @param item that user wants to see in view page
     * @returns {{path: string}} e.g. 'entities/Person/PER0101' or 'links/PersonLink/PE0625'
     */
    goToViewPage (item) {
      let type
      if (this.allEntitiesIndex.includes(item.model)) {
        type = SearchConstants.VIEW_TYPES.ENTITIES
      } else if (this.allLinksIndex.includes(item.model)) {
        type = SearchConstants.VIEW_TYPES.LINKS
      }

      if (type) {
        const pathString = type + SearchConstants.TEXT.FORWARD_SLASH + item.model + SearchConstants.TEXT.FORWARD_SLASH + item._id
        return {
          path: pathString
        }
      } else {
        return ''
      }
    },
    /**
     * To process when user select index in filter
     * @param criteria Index criteria selected by user
     */
    async onSelectIndexFilterCriteria (criteria) {
      // trigger search for new filter
      this.filterByIndex.push(criteria.id)
      this.fetchSearchResultList(false)

      if (criteria.id === SearchConstants.TEXT.WORD.ALL_ENTITIES ||
              criteria.id === SearchConstants.TEXT.WORD.ALL_LINKS) {
        // No need to display filter-by-fields
        return
      }

      // To display filter-by-fields of selected entity
      const model = await this.getModelDetails(criteria.id)
      const fieldsOfModel = model && model.fields ? _.filter(model.fields, function (f) { return f.canSearch }) : []
      const fieldsFilterCriteriaDetail = {
        criteriaKey: criteria.label,
        criteriaValues: fieldsOfModel,
        selectedValue: []
      }
      this.fieldsFilterCriteria.push(_.cloneDeep(fieldsFilterCriteriaDetail))
    },
    /**
     * To process when user removes index in filter
     * @param criteria Index criteria removed by user
     */
    onRemoveIndexFilterCriteria (criteria) {
      if (criteria.id !== SearchConstants.TEXT.WORD.ALL_ENTITIES &&
        criteria.id !== SearchConstants.TEXT.WORD.ALL_LINKS) {
        // Has display filter-by-fields
        const fieldsFilterCriteriaOfIndex = this.fieldsFilterCriteria.find((i) => i.criteriaKey === criteria.label)

        // To remove any selected filter-by-fields of index
        for (const idx in fieldsFilterCriteriaOfIndex.selectedValue) {
          _.remove(this.filterByFields, function (fieldId) {
            return fieldsFilterCriteriaOfIndex.selectedValue[idx].value === fieldId
          })
        }

        // To remove filter-by-fields of index
        _.remove(this.fieldsFilterCriteria, fieldsFilterCriteriaOfIndex)
      }

      // To remove filter-by-index
      _.remove(this.filterByIndex, function (i) {
        return i === criteria.id
      })

      // Trigger search for new filter
      this.fetchSearchResultList(false)
    },
    /**
     * To process when user select field of index in filter
     * @param criteria Field criteria selected by user
     */
    onSelectFieldsFilterCriteria (criteria) {
      // trigger search for new filter
      this.filterByFields.push(criteria.value)
      this.fetchSearchResultList(false)
    },
    /**
     * To process when user removes field of index in filter
     * @param criteria Field criteria removed by user
     */
    onRemoveFieldsFilterCriteria (criteria) {
      // trigger search for new filter
      _.remove(this.filterByFields, function (i) {
        return i === criteria.value
      })
      this.fetchSearchResultList(false)
    },
    /**
     * To reset filter criteria
     */
    resetFilterCriteria () {
      this.fieldsFilterCriteria = []
      for (const i in this.indexFilterCriteria) {
        this.indexFilterCriteria[i].selectedValue = []
      }
    },
    /**
     * Get icon for display
     * @param model - the model of the item to display icon
     * @returns src url to display icon
     */
    getIcon (item) {
      const model = _.find(this.allModels, function (m) {
        return m.id === item.model
      })
      item.icon = model && model.icon ? 'data:image;base64,' + model.icon : ''
      return item.icon
    },
    /**
     * Get the label of model
     * @param name of model
     * @return model label if exist
     **/
    displayModelLabel (modelName) {
      const model = _.find(this.allModels, function (m) { return m.id === modelName })
      return model && model.label ? model.label : modelName
    },
    /**
     * Prepare model details for search results retrieved
     **/
    async prepareModelDetailsForSearchResult () {
      for (const searchResultItem of this.stateSearchResultList.results) {
        const modelDetail = await this.getModelDetails(searchResultItem.model)
        searchResultItem.fields = modelDetail.fields
      }
    },
    /**
     * Get the details of a specified model
     * @param id of model to retrieve details
     * @return model with fields detail, icon detail is loaded async
     */
    async getModelDetails (modelId) {
      const model = _.find(this.allModels, function (m) {
        return m.id === modelId
      })

      if (!model.retrievedDetail) {
        // get fields
        const fieldsResult = await this.getIndexDisplay({ model: modelId, displayIcon: false, displayFields: true })
        if (fieldsResult && fieldsResult.length > 0) {
          model.fields = fieldsResult[0].fields
        }
        // get icon - loaded async
        this.getIndexDisplay({ model: modelId, displayIcon: true, displayFields: false }).then(iconResult => {
          if (iconResult && iconResult.length > 0) {
            model.icon = iconResult[0].icon
          }
        })

        // indicate as retrievedDetail
        model.retrievedDetail = true
      }

      return model
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.form-list {
  position: relative;
}

.search-and-sort-controls {
  padding: 0 14px;
}

.content {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.results-area {
  margin-top: -15px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: auto;
}

.side-bar-area {
  position: sticky;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 300px;
  top: 56px;
  margin-left: 30px;

  .criteria-group {
    margin-bottom: 7px;
    text-align: left;
  }

  .side-bar-box {
    background: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px;
    margin-bottom: 15px;
  }
}

.content-header-icon {
  margin-right: 10px;
  width: 2.5em;
}

a:hover .content-header-icon{
  fill: black !important;
}

.content-header-badge {
  margin-right: 10px;
  margin-left: 10px;
  vertical-align: text-bottom;
  float: right
}

.result-cards-title{
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

</style>
