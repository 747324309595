<template>
  <div class="results">
    <div class="record" v-for="item of items" :key="item._id">
      <table class="inner shadow-sm fade-in-fast" aria-describedby="result cards">
        <thead>
        <tr class="header">
          <th class="name" scope="col">
            <slot name="name" :item="item"></slot>
          </th>
        </tr>
        </thead>

        <tbody class="fields row">
        <tr
          class="field col-sm-3 col-md-2 col-lg-3"
          v-for="(value, field) of item._source" :key="item._id + '-' + field"
          v-show="displayField(value, field)"
        >
          <th scope="col">
            <strong>
              {{ fieldName(item, field) }}
              <component
                v-bind:style="{ height: '16px', width: '16px' }"
                :is="sortIconStatus(field)"
              />
            </strong>
          </th>
          <td v-encode-highlighted-html="displayValue(value, field, item)"></td>
        </tr>
        </tbody>
        <div class="attachments" id="result-cards-attachments" v-if="item.highlight && item.highlight['attachments.attachment.content']">
          <hr>
          <tbody class="fields row">
             <tr>
                <th scope="col"><strong>{{ $SEARCH('TEXT').WORD.FOUND_IN_ATTACHMENTS }}</strong></th>
                <td>
                  <ul>
                    <li v-for="(highlightedAttachment, index) in
                        item.highlight[$SEARCH('SEARCH_RESULT_ATTACHMENT_CONTENT_FIELD_NAME')]"
                        :key="item._id + '-attachments-' + index">
                      <span v-encode-highlighted-html="highlightedAttachment"></span>
                    </li>
                  </ul>
                </td>
             </tr>
          </tbody>
        </div>
        <tfoot v-if="hasStar" class="row d-flex justify-content-end pr-3">
        <slot name="star" :item="item"></slot>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import LaSortAsc from '@/assets/la-sort-asc.svg'
import LaSortDown from '@/assets/la-sort-desc.svg'
import SearchConstants from '@/constants/SEARCH.json'
import Constants from '@/constants/constants'
import _ from 'lodash'
import CurrencyStringMixin from '@/modules/insight/mixins/CurrencyStringMixin'

export default {
  name: 'result-cards',
  mixins: [CurrencyStringMixin],
  components: {
    LaSortAsc,
    LaSortDown,
    SearchConstants,
    Constants
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    searchQuery: {
      type: [String, null]
    },
    sortField: {
      type: Array,
      default: () => (['_score'])
    },
    loading: {
      type: Boolean
    },
    hasStar: {
      default: false
    },
    hiddenFields: {
      type: Array,
      default: () => ([])
    },
    displayBlankFields: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    sortStatus: '&darr;'
  }),
  methods: {
    /**
     * To display field name
     * @param item - search result item of the field
     * @param fieldId - field name
     * @returns {*} - to return the label of the field name to be displayed, or fieldId if does not exist
     */
    fieldName (item, fieldId) {
      const field = _.find(item.fields, function (f) { return fieldId === f.id })
      return field?.label ? field.label : fieldId
    },
    /**
     * To retrieve field format e.g. Currency
     * @param item - search result item of the field
     * @param fieldId - field name
     * @returns {*} - to return the format of the field e.g. "Currency", or 'null' if does not exist
     */
    fieldFormat (item, fieldId) {
      const field = _.find(item.fields, function (f) { return fieldId === f.id })
      return field?.format ? field.format : null
    },
    /**
     * To evaluate if value is undefined/null/empty
     * @param value - value for evaluation
     * @returns {boolean} true if value is undefined/null/empty
     */
    isBlank (value) {
      return value === undefined || value === null || value.length === 0 || _.isEqual(value, {})
    },
    /**
     * To evaluate if field should be displayed - if exist in hidden fields and if is blank value
     * @param value - value for evaluation
     * @param field - field name for evaluation
     * @returns {boolean} - display field in result card to user if true
     */
    displayField (value, field) {
      const toDisplayFieldIfBlank = this.displayBlankFields ? true : !this.isBlank(value)
      return !this.hiddenFields.includes(field) && toDisplayFieldIfBlank
    },
    /**
     * To format the value to be displayed to user for fields with currency format only
     * @param value - field value for evaluation
     * @param highlightedFieldValue - highlighted field value if exists
     * @returns {*} formatted value for display
     */
    valueDisplayCurrencyFormatter (value, highlightedFieldValue) {
      return highlightedFieldValue ? Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_PREFIX + this.currencyString(value) + Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_POSTFIX : this.currencyString(value)
    },
    /**
     * The default logic to format the value to be displayed to user
     * @param value - field value for evaluation
     * @param highlightedFieldValue - highlighted field value if exists
     * @returns {*} formatted value for display
     */
    valueDisplayDefaultFormatter (value, highlightedFieldValue) {
      if (highlightedFieldValue) {
        // remove highlight html elements to compare if highlighted element has been cut short
        const valueWithoutHighlight = highlightedFieldValue
          .replace(new RegExp(Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_PREFIX, 'g'), '')
          .replace(new RegExp(Constants.LOCAL.HTML_PATTERN.HIGHLIGHT_POSTFIX, 'g'), '')
        return String(value).length === valueWithoutHighlight.length
          ? highlightedFieldValue
          : highlightedFieldValue + SearchConstants.TEXT.WORD.ELLIPSES
      } else {
        return value
      }
    },
    /**
     * To format the value to be displayed to user
     * @param value - field value for evaluation
     * @param field - field for evaluation
     * @param item - the search result item of the field
     * @returns {*} formatted value for display
     */
    displayValue (value, field, item) {
      const fieldFormat = this.fieldFormat(item, field)
      const itemHighlightedFields = item.highlight
      let highlightedFieldValue = null
      if (itemHighlightedFields) {
        const highlightedField = itemHighlightedFields[field] ? itemHighlightedFields[field] : itemHighlightedFields[field + '.text']
        highlightedFieldValue = highlightedField ? highlightedField[0] : null
      }

      if (fieldFormat === this.$INSIGHT('VALIDATION_FORMAT').CURRENCY) {
        return this.valueDisplayCurrencyFormatter(value, highlightedFieldValue)
      } else {
        return this.valueDisplayDefaultFormatter(value, highlightedFieldValue)
      }
    },
    /**
     * To display sort icon beside field name
     * @param field - field name
     * @returns {*|string} - will return sort icon for display if field is sort key
     */
    sortIconStatus (field) {
      let icon = LaSortAsc
      let sortBy = this.sortField[0]
      // Check if descending sort
      if (sortBy.startsWith('-')) {
        sortBy = sortBy.slice(1)
        icon = LaSortDown
      }
      return field === sortBy && !this.loading ? icon : ''
    }
  }
}
</script>

<style lang="scss" scoped>
// Remove default stylings
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

th, td {
  display: block;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

thead > tr{
  margin-bottom: 10px;
}

.attachments ul {
  padding-inline-start: 20px;
  word-break: normal;
}

.results {
  display: flex;
  flex-wrap: wrap;

  .record {
    padding: 14px;
    text-align: left;
    flex-basis: 100%;

    .inner {
      background: #FFF;
      border: 1px solid rgba(0, 0, 0, 0.125);
      flex-grow: 1;
      align-self: stretch;
      padding: 20px;
    }

    .header {
      display: flex;

      .name {
        font-size: 1em;
        font-weight: bold;
        flex-grow: 1;
        // TODO Abstract to default text color

        a {
          color: black;
        }
      }
    }

    .fields {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin: -5px;
      font-size: 0.9em;

      .field {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 5px;
      }
    }
  }
}
</style>
